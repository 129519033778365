import "../styles/SignIn.css";
import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import context from "../context/context";
import Loader from "../Components/Loading/Loading";

export default function SignUp() {
  const a = useContext(context);
  const register = a.register;
  const [state, setState] = useState({ email: "", password: "", name: "" });
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);

  function validate_password(password) {
    let check =
      /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;
    if (password.match(check)) {
      console.log("Your password is strong.");
      return true;
    } else {
      Swal.fire("Enter strong password", "", "error");
      return false;
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.email == "")
      return Swal.fire("Please fill in all the required fields", "", "error");
    if (state.password == "")
      return Swal.fire("Please fill in all the required fields", "", "error");
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(state.email)) {
      if (validate_password(state.password)) {
        try {
          setloading(true)
          const response=await register(state)
          if(response.success){
              Swal.fire("Verify your account","Please check your inbox and junk folder for verification.","success").then(()=>{
                window.location.reload()
              })
          }else{
            Swal.fire(response.message, "", "error");
          }
          setloading(false)
        } catch (err) {
          console.log(err);
          Swal.fire("Something went wrong!", "", "error");
        }
      }
    } else return Swal.fire("Please fill in all the required fields", "", "error");
  };


  

  return (
    <div className="containerSignup pt-3">
      {
        loading?<Loader/>:
      <div className="SignUpBox">
        <h2 className="SignUpHeading">Sign Up</h2>
        <div className="mb-3">
          <Form.Label htmlFor="inputPassword5">
            <div className="EmailText">Full Name</div>
          </Form.Label>
          <Form.Control
            type="text"
            value={state.name}
            onChange={(e) => {
              setState({ ...state, name: e.target.value });
            }}
          />
        </div>
        <div className="mb-3">
          <Form.Label htmlFor="inputPassword5">
            <div className="EmailText">Email</div>
          </Form.Label>
          <div className="d-flex gap-2">
          <Form.Control
            type="email"
            value={state.email}
            onChange={(e) => {
              setState({ ...state, email: e.target.value });
            }}
          // placeholder="Enter Your Email"
          />
          </div>
          <span className="PasswordDetails">
          We will send you an email for account verification.
          </span>
        </div>
        <div className="PasswordBtn position-relative">
          <Form.Label htmlFor="inputPassword5">
            <div className="EmailText">Password</div>
          </Form.Label>
          <Form.Control
            type={show == true ? "text" : "password"}
            value={state.password}
            onChange={(e) => {
              setState({ ...state, password: e.target.value });
            }}
            id="inputPassword5"
            // placeholder="Enter Your Password"
            aria-describedby="passwordHelpBlock"
          />
          {show == true ? (
            <span
              className="eyee"
              onClick={() => {
                setShow(false);
              }}
            >
              <AiOutlineEyeInvisible />
            </span>
          ) : (
            <span
              className="eyee"
              onClick={() => {
                setShow(true);
              }}
            >
              <AiOutlineEye />
            </span>
          )}
          <span className="PasswordDetails">
            8 characters, uppercase, lowercase, number, and special.
          </span>
        </div>
        <div className="bottom-button">
          <div
            className="fw-bold"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Sign Up
          </div>
        </div>
      </div>
}
    </div>
  );
}
