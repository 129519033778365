import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Components/Footer/footer'
import Navbar from './Components/Navbar/navbar'
import './globals.css'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import State from './context/state'
import Home from "./Components/Home/index.jsx"
// import Contact from "./contact/page.jsx"
import Faq from "./faq/page.jsx"
import Payments from "./payments/page.jsx"
import Pro from "./pro/page.jsx"
import Signin from "./signin/page.jsx"
import Signup from "./signup/page.jsx";
import Thankyou from "./Thankyou/page.jsx";
import Account from "./account/page.jsx";
import Forget from "./forgetpass/page.jsx"
import Download from "./download/page.jsx"
import VerifiedPremium from "./VerifiedPremium/page.jsx"
config.autoAddCss = false
const App = () => {
	return (
		<State>
		<Router>
			<Navbar />
			<Routes>
				<Route path='/' element={<Home />}  />
				{/* <Route path='/contact' element={<Contact />}  /> */}
				<Route path='/account' element={<Account />}  />
				<Route path='/download' element={<Download />}  />
				<Route path='/Thankyou' element={<Thankyou />}  />
				<Route path='/signup' element={<Signup />}  />
				<Route path='/signin' element={<Signin />}  />
				<Route path='/payments' element={<Payments />}  />
				<Route path='/premium' element={<Pro />}  />
				<Route path='/faq' element={<Faq />}  />
				<Route path='/forgotPass' element={<Forget />}  />
				<Route path='/verifiedpremium' element={<VerifiedPremium />}  />
			</Routes>
			{/* <Footer /> */}
		</Router>
		</State>
	);
};

export default App;
